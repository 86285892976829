<!-- =========================================================================================
     File Name: categoryList.vue
    Description: Category
    ----------------------------------------------------------------------------------------
    Item Name: KOZO Admin - VueJS Dashboard
    Author:  
    Author URL: 
========================================================================================== -->


<template>
  <div>
    <div>
      <vx-card>
        <h1 class="mx-8 mb-8">Budget of {{ budgetDate.toDateString() }}</h1>
        <!-- ADD -->

        <div
          class="calendar-event-dialog"
          title="Remove"
          vs-accept-text="Add Event"
          @vs-accept="addEvent"
          fullscreen
          :vs-is-valid="validForm"
        >
          <div class="vx-col w-full">
            <div class="mx-0 row mb-6">
              <div class="col-6">
                <multiselect
                  v-model="projects_value"
                  track-by="project_id"
                  label="project_name"
                  :options="projects"
                  name="Project Name"
                  placeholder="Select Project"
                  :searchable="true"
                  @select="categoryList"
                  :allow-empty="false"
                  open-direction="bottom"
                  deselect-label="Can't remove this value"
                >
                  <template slot="singleLabel" slot-scope="{ option }">{{
                    option.project_name
                  }}</template>
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Project Name')"
                    >{{ errors.first("Project Name") }}</span
                  >
                </multiselect>
                <!-- <small class="date-label">Date</small>
                <datepicker name="start-date" v-model="datepicked">{{datepicked}}</datepicker>-->
              </div>
              <div class="col-5"></div>
              <div class="col-1 flex">
                <div slot="fc-header-right" class="flex justify-end">
                  <vs-button
                    icon-pack="feather"
                    icon="icon-plus"
                    @click="promptAddNewEvent(project_id)"
                  />
                </div>
              </div>
            </div>
            <!-- <vs-divider background="dark" class="mb-8" color="#4716BA">Add Budget</vs-divider> -->
            <vs-divider background="dark" class="mb-8" color="var(--vs-primary)"
              >Add Budget</vs-divider
            >

            <div
              v-for="(singleBudget, index) in budgetArray"
              :key="index"
              class="mx-0 row mb-6"
            >
              <div class="col-3">
                <multiselect
                  v-model="singleBudget.array"
                  track-by="category_id"
                  label="category_name"
                  :options="parentCategories"
                  name="Category"
                  :searchable="true"
                  :allow-empty="true"
                  open-direction="bottom"
                  @select="subCategoryList($event, index, 'category_id')"
                  :clear-on-select="false"
                  :preselect-first="true"
                >
                  <!-- @input="onSelectCategory(index,category_id,category_name)" -->
                  <!-- @input="({ category_id })=> this.category_id = category_id" -->
                  <template slot="singleLabel" slot-scope="{ option }">{{
                    option.category_name
                  }}</template>
                </multiselect>
              </div>
              <div class="col-4">
                <multiselect
                  v-model="singleBudget.sub_array"
                  track-by="category_id"
                  label="category_name"
                  :options="childCategories"
                  @select="subCategoryList($event, index, 'sub_category_id')"
                  name="Category"
                  :searchable="true"
                  :allow-empty="true"
                  :clear-on-select="false"
                  :preselect-first="true"
                  open-direction="bottom"
                >
                  <template slot="singleLabel" slot-scope="{ option }">{{
                    option.category_name
                  }}</template>
                </multiselect>
              </div>
              <div class="col-2">
                <vs-input
                  name="event-name"
                  v-validate="'required'"
                  class="w-full"
                  label-placeholder="Budget amount."
                  v-model="singleBudget.budget_amount"
                  type="number"
                ></vs-input>
              </div>
              <div class="col-2">
                <vs-input
                  name="event-name"
                  class="w-full"
                  :disabled="!disabledFrom"
                  label-placeholder="Actual amount."
                  v-model="singleBudget.actual_amount"
                  type="number"
                ></vs-input>
              </div>
              <div class="col-1">
                <vs-button
                  icon-pack="feather"
                  icon="icon-x"
                  @click="onRemoveBudget(index)"
                />
              </div>
            </div>
          </div>
          <!-- 
          <vs-textarea rows="5" label="Add description" v-model="desc" />-->
        </div>
        <div class="mx-10 mt-10 mb-8 flex justify-center">
          <vs-button type="filled" @click.prevent="submitForm" class="mr-3"
            >Submit</vs-button
          >
        </div>
        <vs-divider background="dark" class="mb-8" color="var(--vs-primary)"
          >List Of Budgets</vs-divider
        >

        <!-- <vs-divider background="dark" class="mb-8" color=" #4716BA">List Of Budgets</vs-divider> -->
        <!-- VIEW -->
        <div
          class="calendar-event-dialog"
          title="Delete"
          vs-accept-text="Add Event"
          @vs-accept="addEvent"
          fullscreen
          :vs-is-valid="validForm"
        >
          <div
            v-for="(singleBudgetbyDate, index) in budgetsByProjects"
            :key="index"
            class="mx-0 row mb-6"
          >
            <div class="col-3">
              <multiselect
                v-model="singleBudgetbyDate.array"
                track-by="category_id"
                label="category_name"
                :options="parentCategories"
                name="Category"
                :disabled="!disabledFrom"
                :searchable="true"
                :allow-empty="true"
                @select="subCategoryList($event, index, 'category_id')"
                :clear-on-select="false"
                :preselect-first="true"
                open-direction="bottom"
              >
                <!-- @input="onSelectCategory(index,category_id,category_name)" -->
                <!-- @input="({ category_id })=> this.category_id = category_id" -->
                <template slot="singleLabel" slot-scope="{ option }">{{
                  option.category_name
                }}</template>
              </multiselect>
            </div>
            <div class="col-3">
              <multiselect
                v-model="singleBudgetbyDate.sub_array"
                track-by="category_id"
                label="category_name"
                :options="childCategories"
                @select="subCategoryList($event, index, 'sub_category_id')"
                name="Category"
                :searchable="true"
                :allow-empty="true"
                :clear-on-select="false"
                :preselect-first="true"
                open-direction="bottom"
                :disabled="!disabledFrom"
              >
                <template slot="singleLabel" slot-scope="{ option }">{{
                  option.category_name
                }}</template>
              </multiselect>
            </div>
            <div class="col-2">
              <vs-input
                name="event-name"
                v-validate="'required'"
                class="w-full"
                label-placeholder="Budget amount."
                v-model="singleBudgetbyDate.budget_amount"
                :disabled="!disabledFrom"
                type="number"
              ></vs-input>
            </div>
            <div class="col-2">
              <vs-input
                name="event-name"
                class="w-full"
                label-placeholder="Actual amount."
                v-model="singleBudgetbyDate.actual_amount"
                type="number"
              ></vs-input>
            </div>
            <div class="col-1">
              <vs-button
                icon-pack="feather"
                icon="icon-trash-2"
                @click="DeleteBudget(dailybudget_id)"
              />
            </div>
            <div class="col-1">
              <vs-button
                v-if="updateStatus"
                icon-pack="feather"
                icon="icon-check"
                @click="UpdateBudget()"
              />
              <vs-button
                v-else
                icon-pack="feather"
                icon="icon-edit"
                @click="EditBudget()"
              />
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import VueLocalStorage from "vue-localstorage";
import ProjectService from "@/services/projectService.js";
import categoryService from "@/services/categoryService.js";
import budgetService from "@/services/budgetService.js";
import moment from "moment";
export default {
  components: {},
  props: ["date"],
  data() {
    return {
      project_id: "",
      budgetDate: this.$store.state.budgetDate,
      disabledFrom: false,
      projects: [],
      updateStatus: false,

      // budgetsByDate: [
      //   {
      //     parent_id: "",
      //     category_id: "",
      //     parent_category: "",
      //     category_name: "",
      //     budget_amount: "",
      //     actual_amount: "",
      //     sub_category_id: "",
      //     sub_category_name: "",
      //     array: "",
      //     sub_array: ""
      //   }
      // ],
      budgetsByProjects: [
        {
          parent_id: "",
          category_id: "",
          category_name: "",
          budget_amount: "",
          actual_amount: "",
          sub_category_id: "",
          sub_category_name: "",
          array: "",
          sub_array: "",
          dailybudget_date: "",
        },
      ],
      projects_value: [],
      categoriesByProject: [],
      parentCategories: [],
      childCategories: [],
      category_value: [],
      sub_category_value: [],
      actual: "",
      budget: "",
      budgetArray: [
        {
          parent_id: "",
          category_id: "",
          category_name: "",
          budget_amount: "",
          actual_amount: "",
          sub_category_id: "",
          sub_category_name: "",
          array: "",
          sub_array: "",
        },
      ],
      form: {
        edited_actual_amount: "",
      },
    };
  },

  watch: {
    form: {
      handler: function (newVal, oldVal) {
        if (newVal != oldVal && newVal.length > 0) {
          this.updateStatus = false;
        } else {
          this.updateStatus = true;
        }
      },
      deep: true,
    },
  },
  computed: {
    validForm() {
      return (
        this.title != "" &&
        this.start != "" &&
        this.end != "" &&
        Date.parse(this.end) - Date.parse(this.start) >= 0
      );
    },
  },
  beforeMount() {
    console.log("this.budgetDate :", this.budgetDate);
    let epocbudgetDate = moment(this.budgetDate).valueOf();
    if (this.budgetDate == undefined) {
      this.$router.push({ path: "/apps/calendar/vue-fullcalendar" });
    } else {
      this.getAllBudgetByDate(epocbudgetDate);
      this.getProjectsList();
    }
  },
  mounted() {},
  methods: {
    getAllBudgetByDate: function (budgetDate) {
      budgetService
        .getBudgetByDate(budgetDate)
        .then((response) => {
          const { data } = response;
          if (data.Status) {
            this.budgetsByDate = data.data;
          } else {
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getProjectsList: function () {
      ProjectService.getAllProjects()
        .then((response) => {
          const { data } = response;
          if (data.Status) {
            this.projects = data.data;
          } else {
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    categoryList: function (selectedOption) {
      this.project_id = selectedOption.project_id;
      this.getAllCategoryListByProject(this.project_id);
      let epocbudgetDate2 = moment(this.budgetDate).valueOf();
      this.getAllBudgetByProject(this.project_id, epocbudgetDate2);
      this.budgetArray = [
        {
          parent_id: "",
          category_id: "",
          category_name: "",
          budget_amount: "",
          actual_amount: "",
          sub_category_id: "",
          sub_category_name: "",
          array: "",
          sub_array: "",
        },
      ];
    },

    getAllBudgetByProject: function (id, budgetDate) {
      budgetService
        .getBudgetByProjectId(id, budgetDate)
        .then((response) => {
          const { data } = response;
          if (data.Status) {
            this.budgetsByProjects = data.data;
          } else {
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getAllCategoryListByProject: function (id) {
      categoryService
        .getAllcategoriesByProject(id)
        .then((response) => {
          const { data } = response;
          if (data.Status) {
            this.categoriesByProject = data.data;
            this.parentCategories = data.data.filter((x) => x.parent_id == 0);
          } else {
          }
        })
        .catch((error) => {
          console.log("error :", error);
        });
    },
    getAllSubCategoryListByParentCategory: function (parent, project) {
      categoryService
        .getSubCategoriesByParentCategory({ parent, project })
        .then((response) => {
          const { data } = response;
          if (data.Status) {
            this.categoriesByParent = data.data;
            this.childCategories = this.categoriesByParent.filter(
              (y) => y.parent_id === parent
            );
          } else {
          }
        })
        .catch((error) => {
          console.log("error :", error);
        });
    },
    subCategoryList: function (selectedOption, index, type) {
      if (type == "category_id") {
        this.budgetArray[index].parent_id = 0;
        this.budgetArray[index].category_id = selectedOption.category_id;
        this.budgetArray[index].category_name = selectedOption.category_name;
      } else {
        this.budgetArray[index].parent_id = selectedOption.parent_id;
        this.budgetArray[index].category_id = selectedOption.category_id;
        this.budgetArray[index].sub_category_name =
          selectedOption.category_name;
      }
      this.category_id = selectedOption.category_id;
      this.parent_id = selectedOption.category_id;
      this.getAllSubCategoryListByParentCategory(
        this.parent_id,
        this.project_id
      );
    },
    promptAddNewEvent(id) {
      this.getAllCategoryListByProject(id);
      this.disabledFrom = false;
      this.parentCategories = [];
      this.childCategories = [];
      this.budgetArray.push({
        category_id: "",
        parent_id: "",
        sub_category_id: "",
        sub_category_name: "",
        budget_amount: "",
        actual_amount: "",
        //  project_id: 35,
        //     parent_id: 3562,
        //     category_id: 3563,
        //     budget_amount: 200,
        //     actual_amount: 0,
        //     dailybudget_active: 1
      });
    },
    onRemoveBudget(index) {
      // if (index.length > 0) {
      if (index > 0) {
        this.budgetArray.splice(index, 1);
      } else {
        this.budgetArray = [
          {
            parent_id: "",
            category_id: "",
            category_name: "",
            budget_amount: "",
            actual_amount: "",
            sub_category_id: "",
            sub_category_name: "",
            array: "",
            sub_array: "",
          },
        ];
      }
    },
    submitForm() {
      this.budgetArray = this.budgetArray.map((x) => {
        if (x.actual_amount != NaN || x.actual_amount != null) {
          x.actual_amount = 0;
        }
        return x;
      });

      this.$validator.validateAll().then((result) => {
        if (result) {
          this.submitStatus = false;
          this.$vs.loading();
          let payload_date = moment(this.budgetDate).format("YYYY-MM-DD");
          let payload = {
            Date: payload_date,
            Data: {
              budgetData: this.budgetArray,
              project_id: this.project_id,
              dailybudget_active: 1,
            },
          };
          budgetService
            .setBudget(payload)
            .then((response) => {
              this.$vs.loading.close();
              const { data } = response;
              if (data.Status == true || data.Status == "true") {
                this.$vs.notify({
                  title: "Added Successfully",
                  text: data.message,
                  iconPack: "feather",
                  icon: "check_box",
                  color: "success",
                });
              } else {
                this.$vs.loading.close();
                this.$vs.notify({
                  title: "Error!",
                  text: error.message,
                  iconPack: "feather",
                  icon: "check_box",
                  color: "warning",
                });
              }
            })
            .catch((error) => {
              this.$vs.loading.close();
              this.submitStatus = true;
              console.log("error :", error);
            });
          // if form have no errors
          // alert("form submitted!");
        } else {
          // form have errors
        }
      });
    },
    UpdateBudget(id) {
      this.updateStatus = false;
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$vs.loading();
          let id = this.params.data.dailybudget_id;
          let payload = {
            actual_amount: this.form.edited_actual_amount,
          };
          budgetService
            .editBudget(payload, id)
            .then((response) => {
              this.$vs.loading.close();
              const { data } = response;
              if (data.Status == true || data.Status == "true") {
                this.$vs.notify({
                  title: "Updated!",
                  text: data.Message,

                  iconPack: "feather",
                  icon: "check_box",
                  color: "success",
                });
              } else {
                this.$vs.loading.close();
                this.$vs.notify({
                  title: "Error!",
                  text: data.Message,

                  iconPack: "feather",
                  icon: "check_box",
                  color: "warning",
                });
              }
            })
            .catch((error) => {
              this.$vs.loading.close();
              this.submitStatus = true;
              console.log("error :", error);
            });
          // if form have no errors
          // alert("form submitted!");
        } else {
          // form have errors
        }
      });
    },
    EditBudget() {
      this.updateStatus = true;
    },
    // DeleteBudget(id) {
    //   this.$vs.loading();
    //   let Id = this.params.data.dailybudget_id;
    //   console.log("Id :", Id);
    //   let payload = {
    //     dailybudget_active: 3
    //   };
    //   budgetService
    //     .editBudget(payload, Id)
    //     .then(response => {
    //       this.$vs.loading.close();
    //       const { data } = response;
    //       if (data.Status == true || data.Status == "true") {
    //         console.log("dataadasdad :", data);
    //         this.$vs.notify({
    //           title: "Updated!",
    //           text: data.Message,

    //           iconPack: "feather",
    //           icon: "check_box",
    //           color: "success"
    //         });
    //       } else {
    //         this.$vs.loading.close();
    //         console.log("data :", data);
    //         this.$vs.notify({
    //           title: "Error!",
    //           text: data.Message,

    //           iconPack: "feather",
    //           icon: "check_box",
    //           color: "warning"
    //         });
    //       }
    //     })
    //     .catch(error => {
    //       this.$vs.loading.close();
    //       this.submitStatus = true;
    //       console.log("error :", error);
    //     });
    // },
    addEvent() {
      const obj = {
        title: this.title,
        start: this.start,
        end: this.end,
        label: this.labelLocal,
        desc: this.desc,
      };
      obj.cssClass = "event-" + this.labelColor(this.labelLocal);
      this.$store.dispatch("calendar/addEventToCalendar", obj);
    },
  },
  mounted() {},
};
</script>
